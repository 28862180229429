import { useAppDispatch, useAppSelector } from '@/hooks';
import { Flex, Select } from '@radix-ui/themes';
import { toast } from 'react-toastify';
import { psdEngineApi } from '@/services/psdEngine';
import { useEffect, useState } from 'react';
import {
  MockupVariationElement,
  PrintareaPreset,
  SmartObject,
} from '@/services/types';
import { ReactComponent as PhotoshopIcon } from '@/assets/icons/photoshop.svg';
import { updateActiveSmartObject } from '@/redux/slices/collection';
import { EditPreset } from '@/features/screens/collection/components/editor/components/EditPreset';
import { printareaPresetsApi } from '@/services/printareaPresets';

interface IPrintAreaPresets {
  createdPresetId: number;
  isCreatingPreset: boolean;
}

export const PrintAreaPresets = ({
  createdPresetId,
  isCreatingPreset,
}: IPrintAreaPresets) => {
  const dispatch = useAppDispatch();
  const {
    activeSmartObject,
    activeVariation,
    activeDesignAsset,
    mockup,
    isCreatingVariations,
  } = useAppSelector((state) => state.collectionReducer);
  const [deletedPresetId, setDeletedPresetId] = useState<number>(0);

  const [updatePreset, { isLoading: isUpdating }] =
    printareaPresetsApi.useUpdatePrintareaPresetMutation();
  const [generateSingleMockupVariation] =
    psdEngineApi.useGenerateSingleMockupVariationMutation();

  const applyPreset = async (preset: PrintareaPreset) => {
    try {
      dispatch(
        updateActiveSmartObject({
          print_area: activeSmartObject?.print_area,
          fit: preset.print_area_fit,
          global_asset_top: preset.print_area_top,
          global_asset_left: preset.print_area_left,
          global_asset_width: preset.print_area_width,
          global_asset_height: preset.print_area_height,
          rotate: preset.print_area_rotate,
        })
      );
      await generateSingleMockupVariation({
        mockup_variation_id: activeVariation.id,
        asset_id: activeDesignAsset?.id,
        smart_object_id: activeSmartObject?.id,
        fit: preset.print_area_fit,
        design_area_width: preset.print_area_width,
        design_area_height: preset.print_area_height,
        design_area_left: preset.print_area_left,
        design_area_top: preset.print_area_top,
        rotate: preset.print_area_rotate,
        subscription_type_id: 2,
      }).unwrap();
      await dispatch(
        psdEngineApi.endpoints.getMockupSmartObjects.initiate(
          { mockup_id: mockup.id },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      // toast.success('Preset successfully applied.', {
      //   toastId: 'preset_action_success_apply',
      //   position: 'bottom-right',
      //   autoClose: 5000,
      // });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'preset_action_error_apply',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const isApplied = (preset: PrintareaPreset) => {
    return (
      activeSmartObject.global_asset_top === preset.print_area_top &&
      activeSmartObject.global_asset_left === preset.print_area_left &&
      activeSmartObject.global_asset_width === preset.print_area_width &&
      activeSmartObject.global_asset_height === preset.print_area_height &&
      activeSmartObject.rotate === preset.print_area_rotate &&
      activeSmartObject.fit === preset.print_area_fit
    );
  };

  const [selectedPrintareaPreset, setSelectedPrintareaPreset] = useState<
    PrintareaPreset | undefined
  >(activeSmartObject.print_area_presets.find((preset) => isApplied(preset)));

  // useEffect(() => {
  //   if (activeSmartObject) {
  //     const selectedPreset = activeSmartObject.print_area_presets.find(
  //       (preset) => isApplied(preset)
  //     );
  //     if (selectedPreset) {
  //       setSelectedPrintareaPreset(selectedPreset);
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (selectedPrintareaPreset) {
  //     console.log('apply preset 1');
  //     applyPreset(selectedPrintareaPreset);
  //     // const selectedPreset = activeSmartObject.print_area_presets.find(
  //     //   (preset) => isApplied(preset)
  //     // );
  //     // if (selectedPreset) {
  //     //   applyPreset(selectedPrintareaPreset);
  //     // }
  //   }
  // }, [selectedPrintareaPreset]);

  useEffect(() => {
    if (activeSmartObject && deletedPresetId) {
      const selectedPreset = activeSmartObject.print_area_presets[0];
      if (selectedPreset) {
        setSelectedPrintareaPreset(selectedPreset);
        applyPreset(selectedPreset);
      }
    }
  }, [deletedPresetId]);

  useEffect(() => {
    if (activeSmartObject && createdPresetId) {
      const selectedPreset = activeSmartObject.print_area_presets.find(
        (preset) => preset.id === createdPresetId
      );
      if (selectedPreset) {
        setSelectedPrintareaPreset(selectedPreset);
        applyPreset(selectedPreset);
      }
    }
  }, [createdPresetId]);

  useEffect(() => {
    async function updatePresetAction(
      activeSO: SmartObject,
      activeVar: MockupVariationElement,
      selectedPP: PrintareaPreset
    ) {
      if (selectedPP && !isApplied(selectedPP as PrintareaPreset)) {
        const presetExist = activeSO.print_area_presets.find(
          (preset) => preset.id === selectedPP.id
        );
        if (presetExist) {
          // TODO: activeVariation.export_path keeps previous export_path
          await updatePreset({
            id: selectedPP.id,
            print_area_left: activeSO.global_asset_left ?? 1,
            print_area_top: activeSO.global_asset_top ?? 1,
            print_area_width: activeSO.global_asset_width ?? 1,
            print_area_height: activeSO.global_asset_height ?? 1,
            print_area_rotate: activeSO.rotate,
            print_area_fit: activeSO.fit,
            thumbnail_url: activeVar.export_path,
          }).unwrap();
        }
      }
    }
    if (activeSmartObject) {
      if (
        !isUpdating &&
        selectedPrintareaPreset &&
        !isCreatingPreset &&
        !isCreatingVariations
      ) {
        updatePresetAction(
          activeSmartObject,
          activeVariation,
          selectedPrintareaPreset
        );
      }
    }
  }, [activeSmartObject, activeVariation]);

  return (
    <Flex gap={'3'} align={'center'} width={'100%'}>
      <Select.Root
        value={selectedPrintareaPreset?.id?.toString()}
        onValueChange={(e) => {
          const selectedPreset = activeSmartObject.print_area_presets.find(
            (preset) => preset.id === Number(e)
          );
          setSelectedPrintareaPreset(selectedPreset);
          applyPreset(selectedPreset as PrintareaPreset);
        }}
        disabled={activeSmartObject.print_area_presets.length === 0}
      >
        <Select.Trigger style={{ width: '80%' }} />
        <Select.Content position="popper" style={{ width: '100%' }}>
          {activeSmartObject.print_area_presets.map((preset) => (
            <Select.Item
              key={preset.id}
              value={preset.id.toString()}
              // key={preset.id}
              // preset={preset}
              // updatePreset={updatePresetAction}
              // deletePreset={deletePresetAction}
            >
              <Flex align={'center'} justify={'between'} gap={'2'}>
                {preset.name}
                {preset.reflected_to_psd === 1 && <PhotoshopIcon />}
              </Flex>
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <EditPreset
        preset={selectedPrintareaPreset as PrintareaPreset}
        setDeletedPresetId={setDeletedPresetId}
      />
    </Flex>
  );
};
