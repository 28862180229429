import { Helmet } from 'react-helmet-async';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import './color-sets.scss';
import { Button, Dialog, Flex, Text, TextField } from '@radix-ui/themes';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { useRef, useState } from 'react';
import Uppy from '@uppy/core';
import posthog from 'posthog-js';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { toast } from 'react-toastify';
import { colorsApi } from '@/services/colors';

export const ColorSets = () => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { colorSets } = useAppSelector((state) => state.colorSetsReducer);

  const [colorSetName, setColorSetName] = useState<string>('');

  const [createColorSet, { isLoading: isCreating }] =
    colorsApi.useCreateColorSetMutation();

  const createColorSetAction = async () => {
    try {
      await createColorSet({
        name: colorSetName,
      }).unwrap();
      captureEvent('User Created Color Set', {
        name: colorSetName,
      });
      toast.success('Color Set successfully created.', {
        toastId: 'color_set_action_success',
        position: 'bottom-right',
        autoClose: 5000,
      });
      await dispatch(colorsApi.endpoints.getColorSets.initiate()).unwrap();
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'color_set_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <Flex className="view-wrapper-color-sets" direction={'column'} gap={'4'}>
      <Helmet>
        <title>Dynamic Mockups | Color Sets</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        mt={'5'}
        mx={'5'}
        align={'center'}
        justify={'between'}
      >
        <Text weight={'bold'} size={'5'}>
          Color Sets
        </Text>

        <Dialog.Root>
          <Dialog.Trigger>
            <Button color="gray" variant="outline">
              <PlusIcon className="icon black" />
              <Text size="1" weight="medium">
                New Color Set
              </Text>
            </Button>
          </Dialog.Trigger>

          <Dialog.Content maxWidth="450px" aria-describedby={undefined}>
            <Dialog.Title>Create new Color Set</Dialog.Title>

            <Flex direction="column" gap="3">
              <label>
                <TextField.Root
                  onChange={(e) => setColorSetName(e.target.value)}
                  value={colorSetName}
                  placeholder="Color Set Name"
                />
              </label>
            </Flex>

            <Flex gap="3" mt="4" justify="end">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button
                  variant="solid"
                  color="blue"
                  onClick={() => createColorSetAction()}
                >
                  Create
                </Button>
              </Dialog.Close>
            </Flex>
          </Dialog.Content>
        </Dialog.Root>
      </Flex>
      <ViewWrapper />
    </Flex>
  );
};
