import { Flex, Spinner, Text } from '@radix-ui/themes';

export const LoadingCreatingBatch = () => {
  return (
    <Flex
      width={'100%'}
      height={'100%'}
      align={'center'}
      justify={'center'}
      direction={'column'}
      gap={'5'}
    >
      <Spinner size={'3'} />
      <Text weight={'medium'} size={'2'}>
        We’re creating your mockups, please stay tuned. It won’t take long!
      </Text>
    </Flex>
  );
};
