import { Cross1Icon } from '@radix-ui/react-icons';
import { Flex, Tooltip, IconButton, Text } from '@radix-ui/themes';

interface ISelectedItemsOptionsBar {
  selectedItemsCount: number;
  clearSelectedItemsIds: () => void;
}

export const SelectedItemsOptionsBar = ({
  selectedItemsCount,
  clearSelectedItemsIds,
}: ISelectedItemsOptionsBar) => {
  return selectedItemsCount ? (
    <Flex
      p={'2'}
      pr={'6'}
      pl={'2'}
      gap={'2'}
      align={'center'}
      justify={'start'}
      className={'items-selection-bar'}
      width={'auto'}
    >
      <Flex gap={'3'} align={'center'} width={'105px'}>
        <Tooltip content="Clear selection">
          <IconButton
            variant="ghost"
            color="gray"
            onClick={() => clearSelectedItemsIds()}
          >
            <Cross1Icon />
          </IconButton>
        </Tooltip>
        <Text size={'1'} color="gray" weight={'medium'}>
          {selectedItemsCount} selected
        </Text>
      </Flex>
    </Flex>
  ) : (
    <Flex height={'32px'}></Flex>
  );
};
