import './view-wrapper.scss';
import {
  Flex,
  IconButton,
  Separator,
  Text,
  ScrollArea,
  Grid,
  Box,
  Tabs,
} from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import * as Accordion from '@radix-ui/react-accordion';
import { ReactComponent as AltArrowDownLinear } from '@/assets/icons/alt-arrow-down-linear.svg';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { SelectedItemsOptionsBar } from '../SelectedItemsOptionsBar';
import {
  clearSelectedPsdIds,
  clearSelectedTemplateIds,
  resetMockupBundlesState,
} from '@/redux/slices/mockupBundles';
import { SingleMockup } from './single-mockup/SingleMockup';
import { ViewWrapper } from './ViewWrapper';
import { psdManagementApi } from '@/services/psdManagement';
import { useEffect, useState } from 'react';

export const Step1 = ({}: {}) => {
  const dispatch = useAppDispatch();
  const { myTemplates, publicMockups, selectedPsdIds, selectedTemplateIds } =
    useAppSelector((state) => state.mockupBundlesReducer);
  const myTemplatesData = mockupBundlesApi.useGetMyProjectsQuery(null!, {
    skip: !!myTemplates.length,
    refetchOnMountOrArgChange: true,
  });
  const publicMockupsData = mockupBundlesApi.useGetPublicCatalogsQuery(null!, {
    skip: !!publicMockups.length,
    refetchOnMountOrArgChange: true,
  });

  const psdCategories = psdManagementApi.useGetPsdCategoriesQuery();

  const [tabValue, setTabValue] = useState<string>(
    psdCategories.data?.data?.[0]?.id.toString() || '1'
  );

  const onTabChange = (id: string) => {
    const categories = psdCategories.data?.data;
    if (categories) {
      setTabValue(id.toString());
    }
  };

  return (
    <Flex direction={'column'} gap={'1'}>
      <Text size={'2'}>
        Select mockups, then apply designs in bulk. Just two simple steps!
      </Text>
      <SelectedItemsOptionsBar
        selectedItemsCount={selectedPsdIds.length + selectedTemplateIds.length}
        clearSelectedItemsIds={() => {
          dispatch(clearSelectedPsdIds());
          dispatch(clearSelectedTemplateIds());
        }}
      />
      <Accordion.Root
        className="accordion-root"
        type="single"
        defaultValue="my-mockups"
      >
        <Accordion.Item className="accordion-item" value="my-mockups">
          <Accordion.AccordionTrigger className="accordion-trigger">
            <Text className="trigger-text" size={'2'}>
              My Mockups
            </Text>
            <IconButton asChild color="gray" size={'1'} variant="ghost">
              <AltArrowDownLinear
                className="icon accordion-chevron"
                width={'24px'}
                height={'24px'}
              />
            </IconButton>
          </Accordion.AccordionTrigger>

          <Accordion.AccordionContent className="accordion-content">
            <ScrollArea style={{ height: `calc(58svh)` }}>
              <Grid p={'2'} px={'3'} gap={'2'} className="mockups-list-wrapper">
                {myTemplates?.map((mockup) => (
                  <SingleMockup key={mockup.uuid} myTemplate={mockup} />
                ))}
              </Grid>
            </ScrollArea>
          </Accordion.AccordionContent>
          <Separator />
        </Accordion.Item>
        <Accordion.Item className="accordion-item" value="public-library">
          <Accordion.AccordionTrigger className="accordion-trigger">
            <Text className="trigger-text" size={'2'}>
              Pubic Library
            </Text>
            <IconButton asChild color="gray" size={'1'} variant="ghost">
              <AltArrowDownLinear
                className="icon accordion-chevron"
                width={'24px'}
                height={'24px'}
              />
            </IconButton>
          </Accordion.AccordionTrigger>

          <Accordion.AccordionContent className="accordion-content">
            <ScrollArea style={{ height: `calc(58svh)` }}>
              <Tabs.Root
                value={tabValue}
                onValueChange={(val) => onTabChange(val)}
              >
                <Box px={'1'}>
                  <Tabs.List>
                    {psdCategories.data?.data
                      ?.filter((item) => !item.is_default && item.is_visible)
                      ?.map((category) => (
                        <Tabs.Trigger
                          key={category.id}
                          value={category.id.toString()}
                        >
                          {category.name} ({category.count})
                        </Tabs.Trigger>
                      ))}
                  </Tabs.List>
                </Box>

                <Box mt={'4'}>
                  {psdCategories.data?.data?.map((category) => (
                    <Tabs.Content
                      key={category.id}
                      value={category.id.toString()}
                    >
                      <Grid
                        p={'2'}
                        px={'3'}
                        gap={'2'}
                        className="mockups-list-wrapper"
                      >
                        {publicMockups?.map((mockup) => (
                          <SingleMockup key={mockup.id} publicMockup={mockup} />
                        ))}
                      </Grid>
                    </Tabs.Content>
                  ))}
                </Box>
              </Tabs.Root>
            </ScrollArea>
          </Accordion.AccordionContent>
          <Separator />
        </Accordion.Item>
      </Accordion.Root>
    </Flex>
  );
};
