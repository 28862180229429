import { useAppDispatch, useAppSelector } from '@/hooks';
import './smart-objects.scss';
import { Flex, ScrollArea, Tabs } from '@radix-ui/themes';
import { setActiveSmartObject } from '@/redux/slices/collection';
import { setActiveView } from '@/redux/slices/editor';
import { psdEngineApi } from '@/services/psdEngine';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { workspaceApi } from '@/services/workspace';
import { SmartObject } from '@/services/types';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface ISmartObjects {
  smartObjectsData: SmartObject[];
  isLoadingSmartObjects: boolean;
}

export const SmartObjects = ({
  smartObjectsData,
  isLoadingSmartObjects,
}: ISmartObjects) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { collectionId } = useParams();
  const { activeSmartObject, smartObjects } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { currentMockupBundle } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const canUseAiBackgrounds = useFeatureFlagEnabled('ai-backgrounds');

  useEffect(() => {
    if (currentMockupBundle) {
      if (activeSmartObject) {
        navigate(
          `/batch/${currentMockupBundle.mockup_bundle.uuid}/mockup/${collectionId}/smart-object/${activeSmartObject.uuid}`
        );
        setTabValue(activeSmartObject.id.toString());
      }
    } else {
      if (activeSmartObject) {
        navigate(
          `/mockup/${collectionId}/smart-object/${activeSmartObject.uuid}`
        );
        setTabValue(activeSmartObject.id.toString());
      }
    }
  }, [activeSmartObject, collectionId]);

  useEffect(() => {
    if (currentMockupBundle) {
      if (activeSmartObject && !location.pathname.includes('smart-object')) {
        navigate(
          `/batch/${currentMockupBundle.mockup_bundle.uuid}/mockup/${collectionId}/smart-object/${activeSmartObject.uuid}`
        );
        setTabValue(activeSmartObject.id.toString());
      }
    } else {
      if (activeSmartObject && !location.pathname.includes('smart-object')) {
        navigate(
          `/mockup/${collectionId}/smart-object/${activeSmartObject.uuid}`
        );
        setTabValue(activeSmartObject.id.toString());
      }
    }
  }, [location]);

  // psdEngineApi.useGetMockupSmartObjectAssetsQuery(
  //   {
  //     smart_object_id: activeSmartObject?.id,
  //   },
  //   {
  //     skip: !activeSmartObject?.id,
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  const [tabValue, setTabValue] = useState<string>(
    activeSmartObject?.id.toString() || '0'
  );

  const changeActiveSmartObject = (id: number) => {
    setTabValue(id.toString());

    dispatch(setActiveSmartObject(smartObjects.find((so) => so.id === id)));
    dispatch(setActiveView('preview'));
  };

  return (
    <Flex
      className="smart-objects-wrapper"
      direction={'row'}
      style={{
        marginTop: isMobile ? '-30px' : '0px',
        borderTop: isMobile ? '1px solid #DFE3E6' : 'none',
      }}
    >
      {!isLoadingSmartObjects && (smartObjectsData || []).length > 0 ? (
        (smartObjectsData || []).length > 1 && (
          <Tabs.Root
            value={tabValue}
            onValueChange={(val) => changeActiveSmartObject(parseInt(val))}
          >
            <Tabs.List>
              <ScrollArea
                type="hover"
                scrollbars="horizontal"
                style={{ height: '50px', display: 'flex !important' }}
              >
                {smartObjectsData.map((smartObject) =>
                  smartObject?.is_background_layer ? (
                    canUseAiBackgrounds && (
                      <Tabs.Trigger
                        key={smartObject.id}
                        value={smartObject.id.toString()}
                      >
                        {'✨ '}
                        AI{' '}
                        {smartObject.smart_object_name.replace(
                          'dm:ai:background',
                          ''
                        )}
                      </Tabs.Trigger>
                    )
                  ) : (
                    <Tabs.Trigger
                      key={smartObject.id}
                      value={smartObject.id.toString()}
                    >
                      {smartObject.smart_object_name}
                    </Tabs.Trigger>
                  )
                )}
              </ScrollArea>
            </Tabs.List>
          </Tabs.Root>
        )
      ) : (
        <Skeleton
          width={'100%'}
          style={{
            minWidth: '342px',
            height: '40px',
            marginLeft: '16px',
            marginRight: '16px',
          }}
        ></Skeleton>
      )}
    </Flex>
  );
};
