import './view-wrapper.scss';
import { Flex, Button, Box } from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Step1 } from './Step1';
import { useEffect, useState } from 'react';
import { Step2 } from './Step2';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { useNavigate } from 'react-router-dom';
import { resetMockupBundlesState } from '@/redux/slices/mockupBundles';

export const ViewWrapper = ({}: {}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedPsdIds, selectedDesignIds, selectedTemplateIds } =
    useAppSelector((state) => state.mockupBundlesReducer);
  const [step, setStep] = useState<1 | 2>(1);

  const [createMockupBundle, { isLoading: isCreatingBundle }] =
    mockupBundlesApi.useCreateMockupBundleMutation();

  const createBundle = async () => {
    const bundle = await createMockupBundle({
      psd_ids: selectedPsdIds,
      mockup_ids: selectedTemplateIds,
      design_ids: selectedDesignIds,
    }).unwrap();
    navigate(
      `/batch/${bundle.data.mockup_bundle.uuid}/mockup/${bundle.data.mockups[0].uuid}/smart-object/${bundle.data.mockups[0].smart_objects[0].uuid}`
    );
  };

  useEffect(() => {
    dispatch(resetMockupBundlesState());
  }, []);

  return (
    <Flex
      className={`view-wrapper mockup-bundles-wrapper`}
      gap={'4'}
      px={'5'}
      height={'100%'}
      direction={'column'}
      pb={'5'}
    >
      {step === 1 && <Step1 />}
      {step === 2 && <Step2 />}

      <Flex justify={'between'}>
        {step === 1 && <Box></Box>}
        {step === 1 && (
          <Button
            onClick={() => setStep(2)}
            disabled={!selectedPsdIds.length && !selectedTemplateIds.length}
          >
            Next Step
          </Button>
        )}
        {step === 2 && (
          <Button onClick={() => setStep(1)}>Previous Step</Button>
        )}
        {step === 2 && (
          <Button
            onClick={() => createBundle()}
            disabled={
              !selectedDesignIds.length ||
              (!selectedPsdIds.length && !selectedTemplateIds.length) ||
              isCreatingBundle
            }
            loading={isCreatingBundle}
          >
            Create Bundle
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
