import { Box, Button, Flex, ScrollArea, Text } from '@radix-ui/themes';
import './create-mockups.scss';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ChooseDesignOrMockup } from './components/choose-design-or-mockup/ChooseDesignOrMockup';
import { ChooseDesign } from './components/choose-design/ChooseDesign';
import { ChooseMockup } from './components/choose-mockup/ChooseMockup';
import { useAppSelector } from '@/hooks';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { LoadingCreatingBatch } from './components/LoadingCreatingBatch';
import { CreateMockupsFooter } from './components/choose-mockup/footer/CreateMockupsFooter';

export const CreateMockups = () => {
  const navigate = useNavigate();
  const {
    designs,
    myTemplates,
    publicMockups,
    selectedDesignIds,
    selectedPsdIds,
    selectedTemplateIds,
  } = useAppSelector((state) => state.mockupBundlesReducer);
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const [stepName, setStepName] = useState<'choose' | 'mockups' | 'designs'>(
    'choose'
  );

  const [createMockupBundle, { isLoading: isCreatingBatch }] =
    mockupBundlesApi.useCreateMockupBundleMutation();

  const myTemplatesData = mockupBundlesApi.useGetMyProjectsQuery(null!, {
    skip: !!myTemplates.length,
    refetchOnMountOrArgChange: true,
  });
  const publicMockupsData = mockupBundlesApi.useGetPublicCatalogsQuery(null!, {
    skip: !!publicMockups.length,
    refetchOnMountOrArgChange: true,
  });

  const designsData = mockupBundlesApi.useGetDesignsQuery(null!, {
    skip: !!designs.length,
    refetchOnMountOrArgChange: true,
  });

  const getButtonText = () => {
    //  {stepName === 'designs' ? step===2?'Choose Mockups' : }

    if (step === 2) {
      if (stepName === 'designs') {
        return 'Choose Mockups';
      }
      if (stepName === 'mockups') {
        return 'Choose Designs';
      }
    }

    if (step === 3) {
      return 'Create Batch';
    }
  };

  const goToNextAction = () => {
    if (stepName === 'designs' && step === 2) {
      setStepName('mockups');
      setStep(3);
    }
    if (stepName === 'designs' && step === 3) {
      setStep(4);
      createBatch();
    }

    if (stepName === 'mockups' && step === 2) {
      setStepName('designs');
      setStep(3);
    }
    if (stepName === 'mockups' && step === 3) {
      setStep(4);
      createBatch();
    }
  };

  const createBatch = async () => {
    console.log('createBatch');
    const bundle = await createMockupBundle({
      psd_ids: selectedPsdIds,
      mockup_ids: selectedTemplateIds,
      design_ids: selectedDesignIds,
    }).unwrap();
    console.log('bundle', bundle);
    navigate(
      `/batch/${bundle.data.mockup_bundle.uuid}/mockup/${bundle.data.mockups[0].uuid}/smart-object/${bundle.data.mockups[0].smart_objects[0].uuid}`
    );
  };

  return step === 4 ? (
    <LoadingCreatingBatch />
  ) : (
    <Flex
      className="create-mockups-page-wrapper"
      direction={'column'}
      justify={'between'}
    >
      <Flex
        className="header"
        justify={'between'}
        align={'center'}
        gap={'4'}
        py={'2'}
        px={'4'}
        pl={'5'}
      >
        <Flex className="back-wrapper" gap={'3'} align={'center'}>
          <Button
            variant="ghost"
            color="gray"
            size={'1'}
            onClick={() => navigate(`/my-templates`)}
          >
            <ArrowLeft width="14px" height="14px" className="icon" />{' '}
            <Text weight={'medium'} size={'2'}>
              Home
            </Text>
          </Button>
          <Flex gap={'3'} align={'center'}>
            <ArrowLeft width="14px" height="14px" className="icon flip gray" />
            <Text
              className="breadcrumb"
              weight={'medium'}
              size={'2'}
              color="gray"
              onClick={() => {
                setStep(1);
                setStepName('choose');
              }}
            >
              Create Mockups
            </Text>
          </Flex>
          {step === 2 && stepName === 'designs' && (
            <Flex gap={'3'} align={'center'}>
              <ArrowLeft
                width="14px"
                height="14px"
                className="icon flip gray"
              />
              <Text
                className="breadcrumb"
                weight={'medium'}
                size={'2'}
                color="gray"
                onClick={() => {
                  setStep(2);
                  setStepName('designs');
                }}
              >
                Designs
              </Text>
            </Flex>
          )}
          {step === 2 && stepName === 'mockups' && (
            <Flex gap={'3'} align={'center'}>
              <ArrowLeft
                width="14px"
                height="14px"
                className="icon flip gray"
              />
              <Text
                className="breadcrumb"
                weight={'medium'}
                size={'2'}
                color="gray"
                onClick={() => {
                  setStep(2);
                  setStepName('mockups');
                }}
              >
                Mockups
              </Text>
            </Flex>
          )}
          {step === 3 && stepName === 'designs' && (
            <Flex gap={'3'} align={'center'}>
              <ArrowLeft
                width="14px"
                height="14px"
                className="icon flip gray"
              />
              <Text
                className="breadcrumb last"
                weight={'medium'}
                size={'2'}
                color="gray"
                onClick={() => {
                  setStep(3);
                  setStepName('designs');
                }}
              >
                Designs
              </Text>
            </Flex>
          )}
          {step === 3 && stepName === 'mockups' && (
            <Flex gap={'3'} align={'center'}>
              <ArrowLeft
                width="14px"
                height="14px"
                className="icon flip gray"
              />
              <Text
                className="breadcrumb last"
                weight={'medium'}
                size={'2'}
                color="gray"
                onClick={() => {
                  setStep(3);
                  setStepName('mockups');
                }}
              >
                Mockups
              </Text>
            </Flex>
          )}
        </Flex>
        {/* <Text weight={'medium'} size={'2'}>
          {step}/3
        </Text> */}
        {step === 2 || step === 3 ? (
          <Button
            disabled={
              (stepName === 'designs' && selectedDesignIds.length === 0) ||
              (stepName === 'mockups' &&
                selectedPsdIds.length === 0 &&
                selectedTemplateIds.length === 0)
            }
            onClick={() => goToNextAction()}
          >
            <Text weight={'medium'} size={'2'}>
              {getButtonText()}
            </Text>
          </Button>
        ) : (
          <Box></Box>
        )}
      </Flex>
      <Flex className="content">
        <ScrollArea style={{ flex: 1, overflowY: 'auto' }}>
          {step === 1 && stepName === 'choose' && (
            <ChooseDesignOrMockup setStep={setStep} setStepName={setStepName} />
          )}
          {stepName === 'designs' && (
            <ChooseDesign setStep={setStep} setStepName={setStepName} />
          )}
          {stepName === 'mockups' && (
            <ChooseMockup setStep={setStep} setStepName={setStepName} />
          )}
        </ScrollArea>
      </Flex>

      <CreateMockupsFooter />
    </Flex>
  );
};
