import './view-wrapper.scss';
import { Flex, Text, ScrollArea, Grid } from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { mockupBundlesApi } from '@/services/mockupBundles';
import { clearSelectedDesignIds } from '@/redux/slices/mockupBundles';
import { SelectedDesignsOptionsBar } from '../../../designs/components/view-wrapper/SelectedDesignsOptionsBar';
import { SingleDesign } from './single-design/SingleDesign';

export const Step2 = ({}: {}) => {
  const dispatch = useAppDispatch();
  const { designs, selectedDesignIds } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );

  const designsData = mockupBundlesApi.useGetDesignsQuery(null!, {
    skip: !!designs.length,
    refetchOnMountOrArgChange: true,
  });

  return (
    <Flex direction={'column'} gap={'1'}>
      <Text size={'2'}>
        Select mockups, then apply designs in bulk. Just two simple steps!
      </Text>

      <Flex
        className="view-wrapper-design-templates body"
        direction={'column'}
        position={'relative'}
      >
        <SelectedDesignsOptionsBar
          selectedDesigns={selectedDesignIds}
          setSelectedDesigns={(x) => dispatch(clearSelectedDesignIds())}
        />
        <ScrollArea
          className="scroll-area-wrapper"
          type="hover"
          scrollbars="vertical"
          style={{ height: 'calc(69.5vh)', position: 'relative' }}
          id="scroll-container"
        >
          <Grid p={'2'} px={'3'} gap={'2'} className="mockups-list-wrapper">
            {designs?.map((design) => (
              <SingleDesign key={design.id} design={design} />
            ))}
          </Grid>
        </ScrollArea>
      </Flex>
    </Flex>
  );
};
