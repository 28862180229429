import { Design } from '@/services/types';
import { Flex, Tooltip, Text } from '@radix-ui/themes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './single-design.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  addSelectedDesignId,
  removeSelectedDesignId,
} from '@/redux/slices/mockupBundles';

interface ISingleDesign {
  design: Design;
}

export const SingleDesign = ({ design }: ISingleDesign) => {
  const dispatch = useAppDispatch();
  const { selectedDesignIds } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDesignIds.includes(design.id)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedDesignIds, design]);

  const toggleMockupSelect = () => {
    if (!isSelected) {
      dispatch(addSelectedDesignId(design.id));
    } else {
      dispatch(removeSelectedDesignId(design.id));
    }
    setIsSelected((prev) => !prev);
  };

  return (
    <Flex
      onClick={() => {
        toggleMockupSelect();
      }}
      className={`single-design-bundle ${isSelected ? 'selected' : ''}`}
      align={'end'}
    >
      <LazyLoadImage
        alt={`${design.name}`}
        className=""
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          objectPosition: 'center',
        }}
        effect="blur"
        src={
          design.thumbnail_image_url ||
          design.full_image_url ||
          'https://placehold.co/300x300?text=DESIGN'
        }
        delayTime={0}
        placeholderSrc={
          design.thumbnail_image_url ||
          design.full_image_url ||
          'https://placehold.co/300x300?text=DESIGN'
        }
        wrapperProps={{
          style: { transitionDelay: '0s' },
        }}
      />

      <Flex className="text-wrapper" align={'end'} p={'4'}>
        <Tooltip content={design.name}>
          <Text size={'2'} weight={'regular'} style={{ color: '#fff' }}>
            {design.name}
          </Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
