import { useAppDispatch, useAppSelector } from '@/hooks';
import { printareaPresetsApi } from '@/services/printareaPresets';
import { psdEngineApi } from '@/services/psdEngine';
import { InfoCircledIcon, PlusIcon } from '@radix-ui/react-icons';
import {
  Dialog,
  Button,
  Flex,
  TextField,
  Tooltip,
  Checkbox,
  Text,
} from '@radix-ui/themes';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useRef, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IFormInput {
  presetName: string;
  psdApply: boolean;
}

interface ICreateNewPreset {
  setCreatedPresetId: (id: number) => void;
  setIsCreatingPreset: (status: boolean) => void;
}

export const CreateNewPreset = ({
  setCreatedPresetId,
  setIsCreatingPreset,
}: ICreateNewPreset) => {
  const dispatch = useAppDispatch();
  const { activeSmartObject, mockup, activeVariation } = useAppSelector(
    (state) => state.collectionReducer
  );
  const [open, setOpen] = useState<boolean>(false);
  const canReflectPresetToPsd = useFeatureFlagEnabled('reflect-preset-to-psd');

  const [createPrintareaPreset, { isLoading: isCreating }] =
    printareaPresetsApi.useCreatePrintareaPresetMutation();

  const createPreset = async (formInput: IFormInput) => {
    try {
      setIsCreatingPreset(true);
      const createdPreset = await createPrintareaPreset({
        mockup_id: mockup.id,
        smart_object_id: activeSmartObject.id,
        name: formInput.presetName,
        print_area_left: activeSmartObject.global_asset_left ?? 1,
        print_area_top: activeSmartObject.global_asset_top ?? 1,
        print_area_width: (activeSmartObject.global_asset_width ?? 1) + 1,
        print_area_height: activeSmartObject.global_asset_height ?? 1,
        print_area_rotate: activeSmartObject.rotate,
        print_area_fit: activeSmartObject.fit,
        reflect_to_psd: formInput.psdApply,
        thumbnail_url: activeVariation.export_path,
      }).unwrap();
      reset();
      setOpen(false);
      await dispatch(
        psdEngineApi.endpoints.getMockupSmartObjects.initiate(
          { mockup_id: mockup.id },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      setCreatedPresetId(createdPreset.data.id);
      await new Promise((resolve) => setTimeout(resolve, 250));
      setIsCreatingPreset(false);
      toast.success('Preset successfully created.', {
        toastId: 'preset_action_success_create',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'preset_action_error_create',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const formRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormInput>({
    defaultValues: {},
  });
  const onSubmit: SubmitHandler<IFormInput> = (data) => createPreset(data);

  const canReflectPresetToPsdAction = () => {
    if (mockup.from_public_library) {
      if (canReflectPresetToPsd) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button
          variant="outline"
          size={'1'}
          color="gray"
          style={{ padding: '7px' }}
        >
          <PlusIcon width={'10px'} height={'10px'} />
        </Button>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="400px" minHeight={'300px'}>
        <Dialog.Title>Create print area preset</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Create new print area preset out of current active print area
          configuration
        </Dialog.Description>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          ref={formRef}
        >
          <Flex width={'100%'} gap={'1'} direction="column">
            <label>
              <TextField.Slot>
                <Text weight={'medium'} size={'1'} mb={'1'} mt={'3'}>
                  Preset name
                </Text>
              </TextField.Slot>
              <TextField.Root
                size={'2'}
                placeholder="Top right"
                {...register('presetName', {
                  required: {
                    value: true,
                    message: "Preset name field can't be empty",
                  },
                })}
              />
            </label>
            {errors.presetName && (
              <Text role="alert" size={'1'} color="red">
                {errors.presetName.message}
              </Text>
            )}
            {canReflectPresetToPsdAction() && (
              <label>
                <TextField.Slot>
                  <Flex align={'center'} gap={'2'} mb={'1'} mt={'3'}>
                    <Text weight={'medium'} size={'1'}>
                      Apply to Custom Mockup
                    </Text>
                    <Tooltip content="Choose this option to add this preset to your Custom Mockup, making it available for all future templates created from this mockup.">
                      <InfoCircledIcon />
                    </Tooltip>
                  </Flex>
                </TextField.Slot>
                <Controller
                  name="psdApply"
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      checked={value as boolean}
                      onCheckedChange={onChange}
                    />
                  )}
                />
              </label>
            )}
            {canReflectPresetToPsdAction() && errors.psdApply && (
              <Text role="alert" size={'1'} color="red">
                {errors.psdApply.message}
              </Text>
            )}
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button size={'2'} loading={isCreating}>
              Create
            </Button>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
