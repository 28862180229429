import { createSlice } from '@reduxjs/toolkit';

interface IPrintareaPresetsState {
  templatePresets: any;
}

const initialState: IPrintareaPresetsState = {
  templatePresets: null,
};

export const printareaPresetsSlice = createSlice({
  initialState,
  name: 'printareaPresetsSlice',
  reducers: {
    addSelectedPsdId(state, { payload }: { payload: number }) {
      state.templatePresets = payload;
    },
    resetPrintareaPresetsState: () => initialState,
  },
  extraReducers: (builder) => {},
});

export default printareaPresetsSlice.reducer;

export const { addSelectedPsdId, resetPrintareaPresetsState } =
  printareaPresetsSlice.actions;
