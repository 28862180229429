import './left-side-menu.scss';
import { Box, Text, Flex, Button, DropdownMenu } from '@radix-ui/themes';
import { pageSlug } from '../../Workspace';
import { ReactComponent as DesignsIcon } from '@/assets/icons/designs.svg';
import { ReactComponent as AccountIcon } from '@/assets/icons/account.svg';
import { ReactComponent as SlackIcon } from '@/assets/icons/slack.svg';
import { ReactComponent as MenuToggleIcon } from '@/assets/icons/menu-toggle.svg';
import { ReactComponent as ApiCodeIcon } from '@/assets/icons/api-code.svg';
import { ReactComponent as IntegrationsIcon } from '@/assets/icons/integrations.svg';
import { ReactComponent as BulbIcon } from '@/assets/icons/bulb.svg';
import { ReactComponent as BagIcon } from '@/assets/icons/bag.svg';
import { ReactComponent as DCLogoPurpleImage } from '@/assets/design-copilot-logo.svg';
import { ReactComponent as MockupsIcon } from '@/assets/icons/mockups.svg';
import { ReactComponent as PalleteIcon } from '@/assets/icons/pallete-2-linear.svg';
import { useNavigate } from 'react-router-dom';
import {
  BoxModelIcon,
  Component1Icon,
  HamburgerMenuIcon,
  Share1Icon,
} from '@radix-ui/react-icons';
import { useMediaQuery } from 'react-responsive';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useEffect, useRef, useState } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { ReactComponent as ProCrownIcon } from '@/assets/icons/pro-crown.svg';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { setLeftSideMenuWidth } from '@/redux/slices/workspace';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface ILeftSideMenu {
  activeSection: pageSlug;
}

export const LeftSideMenu = ({ activeSection }: ILeftSideMenu) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);

  const [subMenuMockupsOpen, setSubMenuMockupsOpen] = useState(true);

  const [collapsed, setCollapsed] = useState(false);
  const apiDashboardEnabled = useFeatureFlagEnabled('api-dashboard');
  const designsEnabled = useFeatureFlagEnabled('designs');
  const productsEnabled = useFeatureFlagEnabled('products');
  const isAppPro = useFeatureFlagEnabled('app-pro');
  const colorSetsEnabled = useFeatureFlagEnabled('color-sets');
  const mockupBatch = useFeatureFlagEnabled('mockup-batch');

  const leftSideMenu = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (leftSideMenu.current) {
        dispatch(
          setLeftSideMenuWidth((leftSideMenu.current as any).clientWidth)
        );
      }
    };

    // Create a ResizeObserver instance
    const resizeObserver = new ResizeObserver(handleResize);

    // Start observing the element
    if (leftSideMenu.current) {
      resizeObserver.observe(leftSideMenu.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (leftSideMenu.current) {
        resizeObserver.unobserve(leftSideMenu.current);
      }
    };
  }, []);

  return (
    <>
      {!isMobile && (
        <Sidebar
          ref={leftSideMenu}
          collapsed={collapsed}
          collapsedWidth={'44px'}
          width={'280px'}
          className="left-side-menu"
          onMouseEnter={() => setCollapsed(false)}
          style={{
            minHeight: `calc(100vh - 24px ${
              showNotificationBar ? '- 50px' : ''
            })`,
          }}
        >
          <Menu className="menu">
            <MenuItem className="menu-logo">
              <Flex align={'center'} justify={'between'} mb={'2'}>
                <Flex
                  className="logo-wrapper"
                  direction={'row'}
                  align={'center'}
                  gap={'2'}
                  px={'2'}
                  py={'1'}
                  onClick={() => navigate(`/my-templates`)}
                >
                  <DCLogoPurpleImage width={'32px'} height={'32px'} />
                  <Text className="app-name" weight={'bold'} size={'3'}>
                    Dynamic Mockups
                  </Text>
                </Flex>

                <Button
                  size={'1'}
                  aria-label="toggle-menu-button"
                  variant="ghost"
                  color="gray"
                  className="toggle-menu"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <MenuToggleIcon className="icon" width="16" height="16" />
                </Button>
              </Flex>
            </MenuItem>

            {designsEnabled && (
              <MenuItem
                className={`item ${activeSection === 'designs' && 'active'}`}
                onClick={() => navigate('/designs')}
                icon={<DesignsIcon className="icon" width="20" height="20" />}
              >
                <Text className="label" weight={'medium'}>
                  Designs
                </Text>
              </MenuItem>
            )}
            {colorSetsEnabled && (
              <MenuItem
                className={`item ${activeSection === 'colors' && 'active'}`}
                onClick={() => navigate('/colors')}
                icon={<PalleteIcon className="icon" width="20" height="20" />}
              >
                <Text className="label" weight={'medium'}>
                  Colors
                </Text>
              </MenuItem>
            )}
            {mockupBatch && (
              <MenuItem
                className={`item`}
                onClick={() => navigate('/create-mockups')}
                icon={<Component1Icon width="20" height="20" />}
              >
                <Text className="label" weight={'medium'}>
                  Create Mockups
                </Text>
              </MenuItem>
            )}
            <SubMenu
              className={`sub-item ${
                (activeSection === 'mockup-library' ||
                  activeSection === 'custom-mockups' ||
                  activeSection === 'my-templates') &&
                !subMenuMockupsOpen
                  ? 'active'
                  : 'a'
              }`}
              label="Mockups"
              open={subMenuMockupsOpen}
              onClick={() => setSubMenuMockupsOpen(!subMenuMockupsOpen)}
              icon={
                <>
                  <MockupsIcon className="icon" width="20" height="20" />
                  <MenuToggleIcon
                    className="icon nav-arrow"
                    width="16"
                    height="16"
                  />
                </>
              }
            >
              <MenuItem
                className={`item ${
                  activeSection === 'mockup-library' && 'active'
                }`}
                onClick={() => navigate('/mockup-library')}
              >
                <Text className="label" weight={'medium'}>
                  Public Library
                </Text>
              </MenuItem>
              <MenuItem
                className={`item ${
                  activeSection === 'custom-mockups' && 'active'
                }`}
                onClick={() => navigate('/custom-mockups')}
              >
                <Text className="label" weight={'medium'}>
                  Custom Mockups
                </Text>
              </MenuItem>
              <MenuItem
                className={`item ${
                  activeSection === 'my-templates' && 'active'
                }`}
                onClick={() => navigate('/my-templates')}
              >
                <Text className="label" weight={'medium'}>
                  My Mockups
                </Text>
              </MenuItem>
            </SubMenu>
            <Flex height={'16px'}></Flex>
            {productsEnabled && (
              <MenuItem
                className={`item ${
                  activeSection === 'shop-products' && 'active'
                }`}
                onClick={() => navigate('/shop-products/1')}
                icon={<BagIcon className="icon" width="20" height="20" />}
              >
                <Text className="label" weight={'medium'}>
                  Products
                </Text>
              </MenuItem>
            )}
            <MenuItem
              className={`item ${activeSection === 'integrations' && 'active'}`}
              onClick={() => navigate('/integrations')}
              icon={
                <IntegrationsIcon className="icon" width="20" height="20" />
              }
            >
              <Text className="label" weight={'medium'}>
                Integrations
              </Text>
            </MenuItem>
            <Flex height={'16px'}></Flex>
            {apiDashboardEnabled && (
              <MenuItem
                className={`item ${
                  activeSection === 'dashboard-api' && 'active'
                }`}
                onClick={() => navigate('/dashboard-api')}
                icon={<ApiCodeIcon className="icon" width="20" height="20" />}
              >
                <Text className="label" weight={'medium'}>
                  API Dashboard
                </Text>
              </MenuItem>
            )}
            <MenuItem
              className={`item ${
                activeSection === 'mockup-editor-embed-integrations' && 'active'
              }`}
              onClick={() => navigate('/mockup-editor-embed-integrations')}
              icon={<BoxModelIcon width="20" height="20" />}
            >
              <Text className="label" weight={'medium'}>
                Embed Mockup Editor
              </Text>
            </MenuItem>
            <Flex height={'16px'}></Flex>
            <MenuItem
              className={`item`}
              onClick={() =>
                window.open(
                  'https://join.slack.com/t/dynamicmockups/shared_invite/zt-2165e7s2a-sEo2vTTs22fL2tUZdNFUXg',
                  '_blank'
                )
              }
              icon={<SlackIcon width="20" height="20" />}
            >
              <Text className="label" weight={'medium'}>
                Join Slack Community
              </Text>
            </MenuItem>
            <MenuItem
              className={`item`}
              onClick={() =>
                window.open(
                  'https://dynamicmockups.com/affiliate-program/',
                  '_blank'
                )
              }
              icon={<Share1Icon width="20" height="20" />}
            >
              <Text className="label" weight={'medium'}>
                Become Affiliate
              </Text>
            </MenuItem>
            <MenuItem
              className={`item`}
              onClick={() =>
                window.open('https://dynamicmockups.featurebase.app/', '_blank')
              }
              icon={<BulbIcon className="icon" width="20" height="20" />}
            >
              <Text className="label" weight={'medium'}>
                Request a Feature
              </Text>
            </MenuItem>
            <MenuItem
              className={`item ${activeSection === 'account' && 'active'}`}
              onClick={() => navigate('/account')}
              icon={<AccountIcon className="icon" width="20" height="20" />}
            >
              <Text className="label" weight={'medium'}>
                Account
              </Text>
            </MenuItem>
          </Menu>
          {!isAppPro && (
            <Box>
              <Button
                className="get-pro-button"
                size={'1'}
                color="gray"
                variant="outline"
                onClick={() => {
                  captureEvent('Upgrade your plan modal viewed', {
                    source: 'Left sidebar "Get Pro" CTA',
                  });
                  dispatch(setIsAppPaymentPopupOpen(true));
                }}
              >
                <ProCrownIcon width="18" height="18" />{' '}
                {currentUser?.subscription_trial.is_available ? (
                  <Text className="get-pro-text">Start Free Trial</Text>
                ) : (
                  <Text className="get-pro-text">Get Pro</Text>
                )}
              </Button>
            </Box>
          )}
        </Sidebar>
      )}
      {isMobile && (
        <Flex px={'4'} py={'2'} align={'center'} justify={'between'}>
          <Flex
            className="logo-wrapper"
            direction={'row'}
            align={'center'}
            gap={'2'}
            p={'2'}
            mb={'3'}
            onClick={() => navigate(`/my-templates`)}
          >
            <DCLogoPurpleImage width={'32px'} height={'32px'} />
            <Text className="app-name" weight={'bold'} size={'3'}>
              Dynamic Mockups
            </Text>
          </Flex>

          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button
                className="mobile-ham-menu"
                color="gray"
                variant="soft"
                size={'2'}
              >
                <HamburgerMenuIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
              className="mobile-menu-wrapper"
              variant="soft"
              color="gray"
            >
              <Flex
                direction={'column'}
                justify={'between'}
                height={'100%'}
                pb={'4'}
              >
                <Sidebar
                  collapsed={collapsed}
                  collapsedWidth={'44px'}
                  width={'280px'}
                  className="left-side-menu"
                  onMouseEnter={() => setCollapsed(false)}
                >
                  <Menu className="menu">
                    {designsEnabled && (
                      <MenuItem
                        className={`item ${
                          activeSection === 'designs' && 'active'
                        }`}
                        onClick={() => navigate('/designs')}
                        icon={
                          <DesignsIcon
                            className="icon"
                            width="20"
                            height="20"
                          />
                        }
                      >
                        <Text className="label" weight={'medium'}>
                          Designs
                        </Text>
                      </MenuItem>
                    )}
                    {colorSetsEnabled && (
                      <MenuItem
                        className={`item ${
                          activeSection === 'colors' && 'active'
                        }`}
                        onClick={() => navigate('/colors')}
                        icon={
                          <PalleteIcon
                            className="icon"
                            width="20"
                            height="20"
                          />
                        }
                      >
                        <Text className="label" weight={'medium'}>
                          Colors
                        </Text>
                      </MenuItem>
                    )}
                    <SubMenu
                      className={`sub-item ${
                        (activeSection === 'mockup-library' ||
                          activeSection === 'custom-mockups' ||
                          activeSection === 'my-templates') &&
                        !subMenuMockupsOpen
                          ? 'active'
                          : 'a'
                      }`}
                      label="Mockups"
                      open={subMenuMockupsOpen}
                      onClick={() => setSubMenuMockupsOpen(!subMenuMockupsOpen)}
                      icon={
                        <>
                          <MockupsIcon
                            className="icon"
                            width="20"
                            height="20"
                          />
                          <MenuToggleIcon
                            className="icon nav-arrow"
                            width="16"
                            height="16"
                          />
                        </>
                      }
                    >
                      <MenuItem
                        className={`item ${
                          activeSection === 'mockup-library' && 'active'
                        }`}
                        onClick={() => navigate('/mockup-library')}
                      >
                        <Text className="label" weight={'medium'}>
                          Public Library
                        </Text>
                      </MenuItem>
                      <MenuItem
                        className={`item ${
                          activeSection === 'custom-mockups' && 'active'
                        }`}
                        onClick={() => navigate('/custom-mockups')}
                      >
                        <Text className="label" weight={'medium'}>
                          Custom Mockups
                        </Text>
                      </MenuItem>
                      <MenuItem
                        className={`item ${
                          activeSection === 'my-templates' && 'active'
                        }`}
                        onClick={() => navigate('/my-templates')}
                      >
                        <Text className="label" weight={'medium'}>
                          My Mockups
                        </Text>
                      </MenuItem>
                    </SubMenu>
                    <Flex height={'16px'}></Flex>
                    {productsEnabled && (
                      <MenuItem
                        className={`item ${
                          activeSection === 'shop-products' && 'active'
                        }`}
                        onClick={() => navigate('/shop-products/1')}
                        icon={
                          <BagIcon className="icon" width="20" height="20" />
                        }
                      >
                        <Text className="label" weight={'medium'}>
                          Products
                        </Text>
                      </MenuItem>
                    )}
                    <MenuItem
                      className={`item ${
                        activeSection === 'integrations' && 'active'
                      }`}
                      onClick={() => navigate('/integrations')}
                      icon={
                        <IntegrationsIcon
                          className="icon"
                          width="20"
                          height="20"
                        />
                      }
                    >
                      <Text className="label" weight={'medium'}>
                        Integrations
                      </Text>
                    </MenuItem>
                    <Flex height={'16px'}></Flex>
                    {apiDashboardEnabled && (
                      <MenuItem
                        className={`item ${
                          activeSection === 'dashboard-api' && 'active'
                        }`}
                        onClick={() => navigate('/dashboard-api')}
                        icon={
                          <ApiCodeIcon
                            className="icon"
                            width="20"
                            height="20"
                          />
                        }
                      >
                        <Text className="label" weight={'medium'}>
                          API Dashboard
                        </Text>
                      </MenuItem>
                    )}
                    <MenuItem
                      className={`item ${
                        activeSection === 'mockup-editor-embed-integrations' &&
                        'active'
                      }`}
                      onClick={() =>
                        navigate('/mockup-editor-embed-integrations')
                      }
                      icon={<BoxModelIcon width="20" height="20" />}
                    >
                      <Text className="label" weight={'medium'}>
                        Embed Mockup Editor
                      </Text>
                    </MenuItem>
                    <Flex height={'16px'}></Flex>
                    <MenuItem
                      className={`item`}
                      onClick={() =>
                        window.open(
                          'https://join.slack.com/t/dynamicmockups/shared_invite/zt-2165e7s2a-sEo2vTTs22fL2tUZdNFUXg',
                          '_blank'
                        )
                      }
                      icon={<SlackIcon width="20" height="20" />}
                    >
                      <Text className="label" weight={'medium'}>
                        Join Slack Community
                      </Text>
                    </MenuItem>{' '}
                    <MenuItem
                      className={`item`}
                      onClick={() =>
                        window.open(
                          'https://dynamicmockups.com/affiliate-program/',
                          '_blank'
                        )
                      }
                      icon={<Share1Icon width="20" height="20" />}
                    >
                      <Text className="label" weight={'medium'}>
                        Become Affiliate
                      </Text>
                    </MenuItem>
                    <MenuItem
                      className={`item`}
                      onClick={() =>
                        window.open(
                          'https://dynamicmockups.featurebase.app/',
                          '_blank'
                        )
                      }
                      icon={
                        <BulbIcon className="icon" width="20" height="20" />
                      }
                    >
                      <Text className="label" weight={'medium'}>
                        Request a Feature
                      </Text>
                    </MenuItem>
                    <MenuItem
                      className={`item ${
                        activeSection === 'account' && 'active'
                      }`}
                      onClick={() => navigate('/account')}
                      icon={
                        <AccountIcon className="icon" width="20" height="20" />
                      }
                    >
                      <Text className="label" weight={'medium'}>
                        Account
                      </Text>
                    </MenuItem>
                  </Menu>
                  {!isAppPro && (
                    <Box>
                      <Button
                        className="get-pro-button"
                        size={'1'}
                        color="gray"
                        variant="outline"
                        onClick={() => {
                          captureEvent('Upgrade your plan modal viewed', {
                            source: 'Left sidebar "Get Pro" CTA',
                          });
                          dispatch(setIsAppPaymentPopupOpen(true));
                        }}
                      >
                        <ProCrownIcon width="18" height="18" />{' '}
                        {currentUser?.subscription_trial.is_available ? (
                          <Text className="get-pro-text">Start Free Trial</Text>
                        ) : (
                          <Text className="get-pro-text">Get Pro</Text>
                        )}
                      </Button>
                    </Box>
                  )}
                </Sidebar>
              </Flex>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Flex>
      )}
    </>
  );
};

