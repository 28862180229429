import { useAppDispatch, useAppSelector } from '@/hooks';
import { printareaPresetsApi } from '@/services/printareaPresets';
import { psdEngineApi } from '@/services/psdEngine';
import { PrintareaPreset } from '@/services/types';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import {
  Dialog,
  Button,
  Flex,
  TextField,
  Tooltip,
  Checkbox,
  Text,
} from '@radix-ui/themes';
import { useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ReactComponent as TuningLinearIcon } from '@/assets/icons/tuning-linear.svg';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface IFormInput {
  presetName: string;
  psdApply: boolean;
}

interface IEditPreset {
  preset: PrintareaPreset;
  setDeletedPresetId: (id: number) => void;
}

export const EditPreset = ({ preset, setDeletedPresetId }: IEditPreset) => {
  const dispatch = useAppDispatch();
  const { activeSmartObject, mockup, activeVariation } = useAppSelector(
    (state) => state.collectionReducer
  );
  const [open, setOpen] = useState<boolean>(false);
  const canReflectPresetToPsd = useFeatureFlagEnabled('reflect-preset-to-psd');

  const [deletePreset] = printareaPresetsApi.useDeletePrintareaPresetMutation();
  const [updatePreset, { isLoading: isUpdating }] =
    printareaPresetsApi.useUpdatePrintareaPresetMutation();
  const [updatePresetName] =
    printareaPresetsApi.useUpdatePrintareaPresetNameMutation();
  const [reflectPrintareaToPsd] =
    printareaPresetsApi.useReflectPrintareaPresetToPsdMutation();

  const updatePresetAction = async (formInput: IFormInput) => {
    try {
      await updatePresetName({ id: preset.id, name: formInput.presetName });
      // await console({
      //   id: preset.id,
      //   print_area_left: activeSmartObject.global_asset_left ?? 1,
      //   print_area_top: activeSmartObject.global_asset_top ?? 1,
      //   print_area_width: activeSmartObject.global_asset_width ?? 1,
      //   print_area_height: activeSmartObject.global_asset_height ?? 1,
      //   print_area_rotate: activeSmartObject.rotate,
      //   print_area_fit: activeSmartObject.fit,
      //   thumbnail_url: activeVariation.export_path,
      // }).unwrap();
      await reflectPrintareaToPsd({
        presetId: preset.id,
        reflectToPsd: formInput.psdApply,
      });
      setOpen(false);
      await dispatch(
        psdEngineApi.endpoints.getMockupSmartObjects.initiate(
          { mockup_id: mockup.id },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      toast.success('Preset successfully updated.', {
        toastId: 'preset_action_success_update',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'preset_action_error_update',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const deletePresetAction = async (presetId: number) => {
    try {
      await deletePreset({ id: presetId }).unwrap();
      setOpen(false);
      await dispatch(
        psdEngineApi.endpoints.getMockupSmartObjects.initiate(
          { mockup_id: mockup.id },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      setDeletedPresetId(presetId);
      toast.success('Preset successfully deleted.', {
        toastId: 'preset_action_success_delete',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      console.log(error.data);
      toast.error(error.data.message, {
        toastId: 'preset_action_error_delete',
        position: 'bottom-right',
        autoClose: 5000,
      });
    }
  };

  const formRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormInput>({
    values: { presetName: preset?.name, psdApply: !!preset?.reflected_to_psd },
  });
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    updatePresetAction(data);

  useEffect(() => {
    reset();
  }, [open]);

  const canReflectPresetToPsdAction = () => {
    if (mockup.from_public_library) {
      if (canReflectPresetToPsd) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button
          disabled={activeSmartObject.print_area_presets.length === 0}
          variant="ghost"
          size={'2'}
          color="gray"
        >
          <TuningLinearIcon className="icon" width="15" height="15" />
        </Button>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="400px" minHeight={'300px'}>
        <Dialog.Title>
          <Flex align={'center'} justify={'between'}>
            <Text>Edit print area preset</Text>
          </Flex>
        </Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Edit print area preset out of current active print area configuration
        </Dialog.Description>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          ref={formRef}
        >
          <Flex width={'100%'} gap={'1'} direction="column">
            <label>
              <TextField.Slot>
                <Text weight={'medium'} size={'1'} mb={'1'} mt={'3'}>
                  Preset name
                </Text>
              </TextField.Slot>
              <TextField.Root
                size={'2'}
                placeholder="Top right"
                {...register('presetName', {
                  required: {
                    value: true,
                    message: "Preset name field can't be empty",
                  },
                })}
              />
            </label>
            {errors.presetName && (
              <Text role="alert" size={'1'} color="red">
                {errors.presetName.message}
              </Text>
            )}
            {canReflectPresetToPsdAction() && (
              <label>
                <TextField.Slot>
                  <Flex align={'center'} gap={'2'} mb={'1'} mt={'3'}>
                    <Text weight={'medium'} size={'1'}>
                      Apply to Custom Mockup
                    </Text>
                    <Tooltip content="Choose this option to add this preset to your Custom Mockup, making it available for all future templates created from this mockup.">
                      <InfoCircledIcon />
                    </Tooltip>
                  </Flex>
                </TextField.Slot>
                <Controller
                  name="psdApply"
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      checked={value as boolean}
                      onCheckedChange={onChange}
                    />
                  )}
                />
              </label>
            )}
            {canReflectPresetToPsdAction() && errors.psdApply && (
              <Text role="alert" size={'1'} color="red">
                {errors.psdApply.message}
              </Text>
            )}
          </Flex>
          <Flex gap="5" mt="4" justify="between">
            <Flex>
              <Button
                variant="soft"
                color="red"
                onClick={(e) => {
                  e.preventDefault();
                  deletePresetAction(preset?.id);
                }}
              >
                Delete
              </Button>
            </Flex>
            <Flex justify="end" gap="3">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Button size={'2'} loading={isUpdating}>
                Update
              </Button>
            </Flex>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
