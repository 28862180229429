import { mockupBundlesApi } from '@/services/mockupBundles';
import { Design, Mockup, Project, PublicCatalog } from '@/services/types';
import { createSlice } from '@reduxjs/toolkit';

interface ImockupBundlesState {
  currentMockupBundle: {
    mockup_bundle: { uuid: string };
    mockups: Mockup[];
  };
  myTemplates: Project[];
  publicMockups: PublicCatalog[];
  selectedPsdIds: number[];
  selectedTemplateIds: number[];
  selectedDesignIds: number[];
  designs: Design[];
}

const initialState: ImockupBundlesState = {
  currentMockupBundle: null!,
  myTemplates: [],
  publicMockups: [],
  selectedPsdIds: [],
  selectedTemplateIds: [],
  selectedDesignIds: [],
  designs: [],
};

export const mockupBundlesSlice = createSlice({
  initialState,
  name: 'mockupBundlesSlice',
  reducers: {
    addSelectedPsdId(state, { payload }: { payload: number }) {
      if (!state.selectedPsdIds.includes(payload)) {
        state.selectedPsdIds = [...state.selectedPsdIds, payload];
      }
    },
    removeSelectedPsdId(state, { payload }: { payload: number }) {
      state.selectedPsdIds = state.selectedPsdIds.filter(
        (psdId) => psdId !== payload
      );
    },
    addSelectedTemplateId(state, { payload }: { payload: number }) {
      if (!state.selectedTemplateIds.includes(payload)) {
        state.selectedTemplateIds = [...state.selectedTemplateIds, payload];
      }
    },
    removeSelectedTemplateId(state, { payload }: { payload: number }) {
      state.selectedTemplateIds = state.selectedTemplateIds.filter(
        (templateId) => templateId !== payload
      );
    },
    addSelectedDesignId(state, { payload }: { payload: number }) {
      state.selectedDesignIds = [...state.selectedDesignIds, payload];
    },
    removeSelectedDesignId(state, { payload }: { payload: number }) {
      state.selectedDesignIds = state.selectedDesignIds.filter(
        (designId) => designId !== payload
      );
    },
    addSelectedDesignIds(state, { payload }: { payload: number[] }) {
      state.selectedDesignIds = payload;
    },
    clearSelectedDesignIds(state, {}: {}) {
      state.selectedDesignIds = [];
    },
    clearSelectedPsdIds(state, {}: {}) {
      state.selectedPsdIds = [];
    },
    clearSelectedTemplateIds(state, {}: {}) {
      state.selectedTemplateIds = [];
    },
    resetMockupBundlesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      mockupBundlesApi.endpoints.getMockupBundle.matchFulfilled,
      (state, response) => {
        state.currentMockupBundle = response.payload.data;
      }
    );
    builder.addMatcher(
      mockupBundlesApi.endpoints.getMyProjects.matchFulfilled,
      (state, response) => {
        state.myTemplates = response.payload.data;
      }
    );
    builder.addMatcher(
      mockupBundlesApi.endpoints.getPublicCatalogs.matchFulfilled,
      (state, response) => {
        state.publicMockups = response.payload.data;
      }
    );
    builder.addMatcher(
      mockupBundlesApi.endpoints.getDesigns.matchFulfilled,
      (state, response) => {
        state.designs = response.payload.data;
      }
    );
  },
});

export default mockupBundlesSlice.reducer;

export const {
  addSelectedPsdId,
  removeSelectedPsdId,
  addSelectedTemplateId,
  removeSelectedTemplateId,
  addSelectedDesignId,
  clearSelectedDesignIds,
  clearSelectedPsdIds,
  clearSelectedTemplateIds,
  removeSelectedDesignId,
  resetMockupBundlesState,
  addSelectedDesignIds,
} = mockupBundlesSlice.actions;
