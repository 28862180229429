import { Tabs, Box } from '@radix-ui/themes';
import { psdManagementApi } from '@/services/psdManagement';
import { useState } from 'react';
import { ViewWrapper } from '../../../components/catalog/components/view-wrapper/ViewWrapper';
import './choose-public-library.scss';
import { MockupsList } from './MockupsList';
import { TemplatestList } from './TemplatestList';

interface IChooseMyTemplates {
  activeMockupCollectionId: number;
}

export const ChooseMyTemplates = ({
  activeMockupCollectionId,
}: IChooseMyTemplates) => {
  return (
    <Box mt={'4'} width={'100%'}>
      <TemplatestList collectionId={activeMockupCollectionId} />
    </Box>
  );
};
