import { Flex, Box } from '@radix-ui/themes';
import './bundle-mockups-list.scss';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Mockup } from '@/services/types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  clearMockupVariations,
  setCurrentMockup,
  setIsMockupLoading,
} from '@/redux/slices/collection';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';

export const BundleMockupsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { collectionId, bundleId } = useParams();
  const { bundleMockupState } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { currentMockupBundle } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );

  const [activeMockup, setActiveMockup] = useState<Mockup>(null!);

  useEffect(() => {
    if (currentMockupBundle && collectionId) {
      setActiveMockup(
        currentMockupBundle.mockups.find(
          (mockup) => mockup.uuid === collectionId
        ) as Mockup
      );
    }
  }, [collectionId, currentMockupBundle]);

  const setSelectedMockup = (mockupUuid: string) => {
    const selectedMockup = currentMockupBundle.mockups.find(
      (mockup) => mockup.uuid === mockupUuid
    ) as Mockup;
    dispatch(setIsMockupLoading(true));
    dispatch(setCurrentMockup(selectedMockup));
    dispatch(clearMockupVariations());
    navigate(
      `/batch/${currentMockupBundle.mockup_bundle.uuid}/mockup/${selectedMockup.uuid}/smart-object/${selectedMockup.smart_objects[0].uuid}`
    );
  };

  const getCurrentMockupActiveVariation = (mockup: Mockup) => {
    return Object.values(bundleMockupState)
      .flatMap((mockup) => Object.entries(mockup))
      .find(([key]) => key === mockup.uuid)?.[1];
  };

  return (
    <Flex
      className="bundle-mockups-list-wrapper"
      direction={'column'}
      gap={'4'}
      p={'4'}
    >
      {currentMockupBundle?.mockups.length === 0 ? (
        <Skeleton className={`mockup-item`} />
      ) : (
        currentMockupBundle?.mockups.map((mockup) => (
          <Box
            className={`mockup-item ${
              activeMockup?.uuid === mockup.uuid ? 'active' : ''
            }`}
            key={mockup.uuid}
            onClick={() => setSelectedMockup(mockup.uuid)}
          >
            <LazyLoadImage
              height={'100%'}
              width={'100%'}
              style={{
                objectFit: 'cover',
                filter: 'blur(0)',
                maxHeight: '100%',
              }}
              effect="blur"
              src={
                mockup?.thumbnail ||
                'https://placehold.co/120x120?text=MOCKUP_' + mockup?.id
              }
              delayTime={0}
              placeholderSrc={
                mockup?.thumbnail ||
                'https://placehold.co/120x120?text=MOCKUP_' + mockup?.id
              }
            />
          </Box>
        ))
      )}
    </Flex>
  );
};
