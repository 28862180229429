import { Button, Flex, Grid, Text } from '@radix-ui/themes';
import './choose-mockup.scss';
import { ReactComponent as MockupsIcon } from '@/assets/icons/mockups.svg';
import {
  BoxModelIcon,
  Component1Icon,
  ImageIcon,
  LayersIcon,
} from '@radix-ui/react-icons';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as PalleteIcon } from '@/assets/icons/pallete-2-linear.svg';
import { ReactComponent as MenuToggleIcon } from '@/assets/icons/menu-toggle.svg';
import { ReactComponent as WidgetIcon } from '@/assets/icons/widget.svg';
import { ReactComponent as FolderIcon } from '@/assets/icons/folder-linear.svg';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setActiveMockupCollectionId } from '@/redux/slices/mockupCollections';
import { mockupCollectionsApi } from '@/services/mockupCollections';
import { workspaceApi } from '@/services/workspace';
import { ChoosePublicLibrary } from './ChoosePublicLibrary';
import { psdManagementApi } from '@/services/psdManagement';
import { ChooseMyTemplates } from './ChooseMyTemplates';
import Skeleton from 'react-loading-skeleton';

interface IChooseMockup {
  setStep: (step: 1 | 2 | 3) => void;
  setStepName: (step: 'choose' | 'mockups' | 'designs') => void;
}

export const ChooseMockup = ({ setStep, setStepName }: IChooseMockup) => {
  const dispatch = useAppDispatch();
  const [subMenuMockupsOpen, setSubMenuMockupsOpen] = useState(true);
  const [subMenuLibraryOpen, setSubMenuLibraryOpen] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const leftSideMenu = useRef(null);

  const { mockupCollections, activeMockupCollectionId, allTemplatesCount } =
    useAppSelector((state) => state.mockupCollectionsReducer);

  const psdCategories = psdManagementApi.useGetPsdCategoriesQuery();
  const getMockupCollections =
    mockupCollectionsApi.useGetMockupCollectionsQuery(null!, {
      refetchOnMountOrArgChange: true,
    });

  const geyAllMyProjects = workspaceApi.useGetMyProjectsQuery(
    { collectionId: 0 },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [selectedMockupsPage, setSelectedMockupsPage] = useState<
    'public-library' | 'my-templates'
  >('public-library');

  useEffect(() => {
    setSelectedMockupsPage('public-library');
    dispatch(setActiveMockupCollectionId(null));
  }, []);

  const [activePublicLibraryCategoryId, setActivePublicLibraryCategoryId] =
    useState<number>(psdCategories.data?.data?.[0]?.id || 1);

  return (
    <Grid className="choose-mockup-wrapper" columns={'3fr 15fr'}>
      <Flex className="side-menu" p={'4'}>
        <Sidebar
          ref={leftSideMenu}
          collapsed={collapsed}
          collapsedWidth={'44px'}
          width={'280px'}
          className="left-side-menu"
          onMouseEnter={() => setCollapsed(false)}
          style={{
            minHeight: `calc(100vh - 24px)`,
          }}
        >
          <Menu className="menu">
            <SubMenu
              className={`sub-item`}
              label="Public Library"
              open={subMenuLibraryOpen}
              onClick={() => setSubMenuLibraryOpen(!subMenuLibraryOpen)}
              icon={
                <>
                  <MockupsIcon
                    className="icon"
                    width={'20px'}
                    height={'20px'}
                  />
                  <MenuToggleIcon
                    className="icon nav-arrow"
                    width="16"
                    height="16"
                  />
                </>
              }
            >
              {psdCategories.isLoading ? (
                <MenuItem className={`item`}>
                  <Skeleton
                    style={{
                      width: '200px',
                      borderRadius: '8px',
                      height: '36px',
                    }}
                  />
                </MenuItem>
              ) : (
                psdCategories.data?.data
                  ?.filter((item) => !item.is_default && item.is_visible)
                  ?.map((category) => (
                    <MenuItem
                      key={`collection-item-${category.id}`}
                      className={`item  ${
                        activePublicLibraryCategoryId === category.id &&
                        selectedMockupsPage === 'public-library'
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => {
                        setActivePublicLibraryCategoryId(category.id);
                        setSelectedMockupsPage('public-library');
                      }}
                    >
                      <FolderIcon
                        className="icon"
                        width={'15px'}
                        height={'15px'}
                      />{' '}
                      <Text className="label" weight={'medium'}>
                        {category.name}
                      </Text>{' '}
                      {/* ({category.count}) */}
                    </MenuItem>
                  ))
              )}
            </SubMenu>

            <SubMenu
              className={`sub-item`}
              label="My Templates"
              open={subMenuMockupsOpen}
              onClick={() => setSubMenuMockupsOpen(!subMenuMockupsOpen)}
              icon={
                <>
                  <WidgetIcon className="icon" width={'20px'} height={'20px'} />
                  <MenuToggleIcon
                    className="icon nav-arrow"
                    width="16"
                    height="16"
                  />
                </>
              }
            >
              <MenuItem
                className={`item ${
                  activeMockupCollectionId === 0 &&
                  selectedMockupsPage === 'my-templates'
                    ? 'active'
                    : ''
                }`}
                onClick={() => {
                  dispatch(setActiveMockupCollectionId(0));
                  setSelectedMockupsPage('my-templates');
                }}
              >
                <Text className="label" weight={'medium'}>
                  All Templates
                </Text>
              </MenuItem>
              {getMockupCollections.isLoading ? (
                <MenuItem className={`item`}>
                  <Skeleton
                    style={{
                      width: '200px',
                      borderRadius: '8px',
                      height: '36px',
                    }}
                  />
                </MenuItem>
              ) : (
                mockupCollections?.map((collection) => (
                  <MenuItem
                    key={`collection-item-${collection.id}`}
                    className={`item  ${
                      activeMockupCollectionId === collection.id &&
                      selectedMockupsPage === 'my-templates'
                        ? 'active'
                        : ''
                    }`}
                    onClick={() => {
                      dispatch(setActiveMockupCollectionId(collection.id));
                      setSelectedMockupsPage('my-templates');
                    }}
                  >
                    <FolderIcon
                      className="icon"
                      width={'15px'}
                      height={'15px'}
                    />{' '}
                    <Text className="label" weight={'medium'}>
                      {collection.name}
                    </Text>
                  </MenuItem>
                ))
              )}
            </SubMenu>
          </Menu>
        </Sidebar>
      </Flex>
      <Flex className="content" p={'4'} pb={'0px'}>
        {selectedMockupsPage === 'public-library' && (
          <ChoosePublicLibrary
            activePublicLibraryCategoryId={activePublicLibraryCategoryId}
          />
        )}

        {selectedMockupsPage === 'my-templates' && (
          <ChooseMyTemplates
            activeMockupCollectionId={activeMockupCollectionId}
          />
        )}
      </Flex>
    </Grid>
  );
};
