import { Project, PublicCatalog } from '@/services/types';
import { Flex, Tooltip, Text } from '@radix-ui/themes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './single-mockup.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  addSelectedPsdId,
  addSelectedTemplateId,
  removeSelectedPsdId,
  removeSelectedTemplateId,
} from '@/redux/slices/mockupBundles';

interface ISingleMockup {
  myTemplate?: Project;
  publicMockup?: PublicCatalog;
}

export const SingleMockup = ({ myTemplate, publicMockup }: ISingleMockup) => {
  const dispatch = useAppDispatch();
  const { selectedPsdIds, selectedTemplateIds } = useAppSelector(
    (state) => state.mockupBundlesReducer
  );
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    const psdId = publicMockup?.id;
    const templateId = myTemplate?.id;
    if (
      (psdId !== undefined && selectedPsdIds.includes(psdId)) ||
      (templateId !== undefined && selectedTemplateIds.includes(templateId))
    ) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedPsdIds, myTemplate, publicMockup]);

  const toggleMockupSelect = () => {
    const psdId = publicMockup?.id;
    const templateId = myTemplate?.id;
    if (psdId !== undefined) {
      if (!isSelected) {
        dispatch(addSelectedPsdId(psdId));
      } else {
        dispatch(removeSelectedPsdId(psdId));
      }
      setIsSelected((prev) => !prev);
    }
    if (templateId !== undefined) {
      if (!isSelected) {
        dispatch(addSelectedTemplateId(templateId));
      } else {
        dispatch(removeSelectedTemplateId(templateId));
      }
      setIsSelected((prev) => !prev);
    }
  };

  return (
    <Flex
      onClick={() => {
        toggleMockupSelect();
      }}
      className={`single-mockup ${isSelected ? 'selected' : ''}`}
      align={'end'}
    >
      <LazyLoadImage
        alt={`${myTemplate?.name || publicMockup?.name}`}
        className=""
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          objectPosition: 'center',
        }}
        effect="blur"
        src={
          myTemplate?.thumbnail ||
          publicMockup?.thumbnail ||
          'https://placehold.co/300x300?text=MOCKUP'
        }
        delayTime={0}
        placeholderSrc={
          myTemplate?.thumbnail ||
          publicMockup?.thumbnail ||
          'https://placehold.co/300x300?text=MOCKUP'
        }
        wrapperProps={{
          style: { transitionDelay: '0s' },
        }}
      />

      <Flex className="text-wrapper" align={'end'} p={'4'}>
        <Tooltip content={myTemplate?.name || publicMockup?.name}>
          <Text size={'2'} weight={'regular'} style={{ color: '#fff' }}>
            {myTemplate?.name || publicMockup?.name}
          </Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
