import {
  Dialog,
  Flex,
  Grid,
  Text,
  Switch,
  Card,
  Button,
  Select,
  Box,
  VisuallyHidden,
} from '@radix-ui/themes';
import './appPpaymentPopup.scss';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/check-linear.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close-linear.svg';
import { ReactComponent as StraightArrowIcon } from '@/assets/icons/straight-arrow-icon.svg';
import { ReactComponent as ProCrownIcon } from '@/assets/icons/pro-crown.svg';
import { ReactComponent as PremiumCrownIcon } from '@/assets/icons/premium-crown.svg';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { paymentsApi } from '@/services/payments';
import {
  MonthlyPlan,
  monthlyPlansData,
  yearlyPlansData,
  monthlyPlansDataDEV,
  yearlyPlansDataDEV,
} from '../api-dashboard/components/ApiPaymentPopup';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { EnterpriseContactUs } from './EnterpriseContactUs';
import posthog from 'posthog-js';
import { toast } from 'react-toastify';

export const AppPaymentPopup = forwardRef(({}, ref) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const { currentUser } = useAppSelector((state) => state.userReducer);

  const [monthlyApiPlans, setMonthlyApiPlans] = useState(
    !!parseInt(IS_PRODUCTION) ? monthlyPlansData : monthlyPlansDataDEV
  );
  const [yearlyApiPlans, setYearlyApiPlans] = useState(
    !!parseInt(IS_PRODUCTION) ? yearlyPlansData : yearlyPlansDataDEV
  );

  const [isAnnual, setIsAnnual] = useState<boolean>(true);
  const [selectedPanId, setSelectedPlanId] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser) {
      if (isAnnual) {
        setSelectedPlanId(
          !!parseInt(IS_PRODUCTION)
            ? 'price_1PPLpjFy4AZwJXspLQDEZpar'
            : 'price_1Pso4MFy4AZwJXspT4f0pedL' //dev
        );
        let updatedYearlyPlans = yearlyApiPlans.map((plan, index) => ({
          ...plan,
          stripeLink: plan.stripeLink,
          selected: index === 0 ? true : false,
        }));
        setYearlyApiPlans(updatedYearlyPlans);
      } else {
        setSelectedPlanId(
          !!parseInt(IS_PRODUCTION)
            ? 'price_1PPLpAFy4AZwJXspMKKTNv6h'
            : 'price_1Pso5JFy4AZwJXsprIiSg1h0' //dev
        );
        let updatedMonthlyPlans = monthlyApiPlans.map((plan, index) => ({
          ...plan,
          stripeLink: plan.stripeLink,
          selected: index === 0 ? true : false,
        }));
        setMonthlyApiPlans(updatedMonthlyPlans);
      }
    }
  }, [currentUser, isAnnual]);

  useEffect(() => {
    dispatch(setIsAppPaymentPopupOpen(open));
  }, [open]);

  const goToStripePlanCheckout = async () => {
    try {
      const url = await dispatch(
        paymentsApi.endpoints.getCheckoutSessionURL.initiate({
          stripe_price_id: selectedPanId,
          subscription_plan_type: 'app',
        })
      ).unwrap();
      captureEvent('Payment initiated');
      window.location.replace(url.data.checkout_url);
    } catch (error) {
      captureEvent('Payment initiation failed');
      console.log(error);
    }
  };

  useImperativeHandle(ref, () => ({
    openDialog() {
      setActiveView('paywall');
      setOpen(true);
    },
    closeDialog() {
      setOpen(false);
    },
  }));

  const selectMonthlyPlan = (plan: MonthlyPlan) => {
    const updatedPlans = monthlyApiPlans.map((item) => ({
      ...item,
      selected: item.amount === plan.amount,
    }));
    captureEvent(
      `Plan ${plan.amount} Monthly Renders, $${plan.price} / month selected`
    );

    setMonthlyApiPlans(updatedPlans);
  };

  const selectYearlyPlan = (plan: MonthlyPlan) => {
    const updatedPlans = yearlyApiPlans.map((item) => ({
      ...item,
      selected: item.amount === plan.amount,
    }));
    captureEvent(
      `Plan ${plan.amount} Monthly Renders, $${plan.discountedPrice} / year selected`
    );

    setYearlyApiPlans(updatedPlans);
  };

  const getSelectedPlan = () => {
    if (isAnnual) {
      return yearlyApiPlans.find((plan) => plan.selected);
    } else {
      return monthlyApiPlans.find((plan) => plan.selected);
    }
  };

  const goToStripeApiPlanCheckout = async () => {
    try {
      const url = await dispatch(
        paymentsApi.endpoints.getCheckoutSessionURL.initiate({
          stripe_price_id: getSelectedPlan()?.stripeLink || '',
          subscription_plan_type: 'api',
        })
      ).unwrap();
      captureEvent('Payment initiated');
      window.location.replace(url.data.checkout_url);
    } catch (error) {
      captureEvent('Payment initiation failed');
      console.log(error);
    }
  };

  const [activeView, setActiveView] = useState<'paywall' | 'contact-us-form'>(
    'paywall'
  );

  const enterpriseContactUsFormOpen = () => {
    captureEvent('Enterprise Plan Form opened');
    setActiveView('contact-us-form');
  };

  const goToPayWall = () => {
    captureEvent('Enterprise Plan Form closed');
    setActiveView('paywall');
  };

  const closeFormModal = () => {
    setActiveView('contact-us-form');
    captureEvent('Enterprise Plan Form closed');
    setOpen(false);
    toast.success('Thank you for contacting us.', {
      toastId: 'successfull_contact_us',
      position: 'bottom-right',
      autoClose: 5000,
    });
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content
        aria-describedby={undefined}
        className="app-payment-popup-wrapper"
        style={{
          maxWidth: activeView === 'paywall' ? '1510px' : '490px',
          maxHeight: activeView === 'paywall' ? '670px' : '760px',
          minHeight: '464px',
        }}
        width={'100%'}
        onPointerDownOutside={() => {
          captureEvent('Enterprise Plan Form closed');
          captureEvent('Upgrade your plan modal closed');
        }}
      >
        <VisuallyHidden>
          <Dialog.Title></Dialog.Title>
        </VisuallyHidden>
        {activeView === 'contact-us-form' && (
          <EnterpriseContactUs
            goToPayWall={goToPayWall}
            closeFormModal={closeFormModal}
          />
        )}
        {activeView === 'paywall' && (
          <Flex direction={'column'} gap={'5'}>
            <Flex direction={'column'} align={'center'} gap={'5'}>
              <Flex direction={'column'} align={'center'} gap={'3'}>
                <Dialog.Title mb={'0'}>
                  <Text size={'5'} weight={'bold'}>
                    Upgrade your plan
                  </Text>
                </Dialog.Title>

                <Text size={'2'} className="subtitle">
                  Upgrade to access all Mockups and premium features
                </Text>
              </Flex>
              <Flex gap={'2'}>
                <Text size={'2'}>Save 25% with a yearly plan</Text>
                <Switch
                  defaultChecked={isAnnual}
                  onCheckedChange={(checked) => setIsAnnual(checked)}
                />
              </Flex>
            </Flex>
            <Grid
              columns={isMobile ? '1fr' : '3fr 3fr 3fr 3fr'}
              rows={'1fr'}
              gap={'4'}
              className="plans-wrapper"
            >
              <Card className={`item-card`} size={'5'}>
                <Flex direction={'column'} height={'100%'}>
                  <Flex direction={'column'} gap={'2'} px={'5'} py={'6'}>
                    <Text className="title">Free</Text>
                    <Text className="price">$0</Text>
                    <Text className="description">Free forever</Text>
                  </Flex>
                  <Flex
                    className="content"
                    direction={'column'}
                    justify={'between'}
                    height={'100%'}
                    p={'5'}
                    gap={'5'}
                  >
                    <Flex
                      className="item-description"
                      direction={'column'}
                      gap={'2'}
                    >
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          100 Downloads
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          1 Template
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Library of 1.000+ Mockups
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Bulk Mockup Generator
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          High-quality Downloads
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          No Watermak
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap={'2'} className="cta-button-wrapper back">
                      <Button
                        variant={'outline'}
                        color="gray"
                        onClick={() => {
                          captureEvent('Upgrade your plan modal closed');
                          setOpen(false);
                        }}
                      >
                        Stay Basic
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
              <Card className={`item-card pro`} size={'5'}>
                <Flex direction={'column'} height={'100%'}>
                  <Flex direction={'column'} gap={'2'} px={'5'} py={'6'}>
                    <Flex align={'center'} gap={'2'}>
                      <ProCrownIcon width={'24px'} height={'24px'} />
                      <Text className="title">Automate</Text>
                    </Flex>
                    <Flex align={'end'} gap={'1'}>
                      <Text className="price">{isAnnual ? '$14' : '$19'}</Text>
                      <Text>/ month</Text>
                    </Flex>
                    <Text
                      className={`description transition ${
                        isAnnual ? 'visible' : ''
                      }`}
                    >
                      Billed $169 annually
                    </Text>
                  </Flex>
                  <Flex
                    className="content"
                    direction={'column'}
                    justify={'between'}
                    height={'100%'}
                    p={'5'}
                    gap={'5'}
                  >
                    <Flex
                      className="item-description"
                      direction={'column'}
                      gap={'2'}
                    >
                      {/* <Flex
                    gap={'2'}
                    align={'center'}
                    className={`transition ${isAnnual ? 'visible' : ''}`}
                  >
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Save $59 annually
                    </Text>
                  </Flex> */}
                      <Flex gap={'2'} align={'center'}>
                        <StraightArrowIcon
                          className="icon"
                          width={'20px'}
                          height={'16px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Everything from the Free Plan
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          300 Monthly Downloads
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          5 Templates
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Custom Photoshop Mockups
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          API Access
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          AI Mockup Backgrounds ✨
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Customer Support
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap={'2'} className="cta-button-wrapper">
                      {currentUser?.subscription_trial.is_available ? (
                        <Button
                          variant={'solid'}
                          color="blue"
                          onClick={() => goToStripePlanCheckout()}
                        >
                          Start Free Trial
                        </Button>
                      ) : (
                        <Button
                          variant={'solid'}
                          color="blue"
                          onClick={() => goToStripePlanCheckout()}
                        >
                          Upgrade to Automate
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
              <Card className={`item-card premium`} size={'5'}>
                <Flex direction={'column'} height={'100%'}>
                  <Flex direction={'column'} gap={'2'} px={'5'} py={'6'}>
                    <Flex align={'center'} gap={'2'}>
                      <PremiumCrownIcon width={'24px'} height={'24px'} />
                      <Text className="title">Scale</Text>
                    </Flex>
                    <Flex align={'end'} gap={'1'}>
                      <Text className="price">
                        {isAnnual
                          ? `$${
                              yearlyApiPlans.find((plan) => plan.selected)
                                ?.discountedMonthlyPrice
                            }`
                          : `$${
                              monthlyApiPlans.find((plan) => plan.selected)
                                ?.price
                            }`}
                      </Text>
                      <Text>/ month</Text>
                    </Flex>
                    <Text
                      className={`description transition ${
                        isAnnual ? 'visible' : ''
                      }`}
                    >
                      {`Billed $${
                        yearlyApiPlans.find((plan) => plan.selected)
                          ?.discountedPrice
                      } annually`}
                    </Text>
                  </Flex>

                  <Flex
                    className="content"
                    direction={'column'}
                    justify={'between'}
                    height={'100%'}
                    p={'5'}
                    gap={'5'}
                  >
                    <Select.Root
                      value={
                        isAnnual
                          ? yearlyApiPlans.find((plan) => plan.selected)
                              ?.stripeLink
                          : monthlyApiPlans.find((plan) => plan.selected)
                              ?.stripeLink
                      }
                      onValueChange={(e) => {
                        if (isAnnual) {
                          selectYearlyPlan(
                            yearlyApiPlans.find(
                              (plan) => plan.stripeLink === e
                            ) as MonthlyPlan
                          );
                        } else {
                          selectMonthlyPlan(
                            monthlyApiPlans.find(
                              (plan) => plan.stripeLink === e
                            ) as MonthlyPlan
                          );
                        }
                      }}
                    >
                      <Select.Trigger />
                      <Select.Content position="popper">
                        {(isAnnual ? yearlyApiPlans : monthlyApiPlans).map(
                          (plan) => (
                            <Select.Item
                              value={plan.stripeLink}
                              key={plan.stripeLink}
                            >
                              {plan.amount} monthly API renders
                            </Select.Item>
                          )
                        )}
                      </Select.Content>
                    </Select.Root>

                    <Flex
                      className="item-description"
                      direction={'column'}
                      gap={'2'}
                    >
                      {/* <Flex
                    gap={'2'}
                    align={'center'}
                    className={`transition ${isAnnual ? 'visible' : ''}`}
                  >
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      {`Save $${
                        yearlyApiPlans.find((plan) => plan.selected)?.saved
                      } annually`}
                    </Text>
                  </Flex> */}
                      <Flex gap={'2'} align={'center'}>
                        <StraightArrowIcon
                          className="icon"
                          width={'20px'}
                          height={'16px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Everything from the Automate Plan
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          50 Templates
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Embed Mockup Editor
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Color Presets
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Priority Customer Support
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap={'2'} className="cta-button-wrapper">
                      {currentUser?.subscription_trial.is_available ? (
                        <Button
                          variant={'solid'}
                          color="purple"
                          onClick={() => goToStripeApiPlanCheckout()}
                        >
                          Start Free Trial
                        </Button>
                      ) : (
                        <Button
                          variant={'solid'}
                          color="purple"
                          onClick={() => goToStripeApiPlanCheckout()}
                        >
                          Upgrade to Scale
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
              <Card className={`item-card enterprise`} size={'5'}>
                <Flex direction={'column'} height={'100%'}>
                  <Flex direction={'column'} gap={'2'} px={'5'} py={'6'}>
                    <Flex align={'center'} gap={'2'}>
                      <ProCrownIcon width={'24px'} height={'24px'} />
                      <Text className="title">Custom</Text>
                    </Flex>
                    <Flex align={'end'} gap={'1'}>
                      <Text className="price">Let's talk</Text>
                    </Flex>
                    <Box
                      className={`description transition ${
                        isAnnual ? 'visible' : ''
                      }`}
                      style={{ height: isAnnual ? '13px' : '0' }}
                    ></Box>
                  </Flex>
                  <Flex
                    className="content"
                    direction={'column'}
                    justify={'between'}
                    height={'100%'}
                    p={'5'}
                    gap={'5'}
                  >
                    <Flex
                      className="item-description"
                      direction={'column'}
                      gap={'2'}
                    >
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Volume based discounts
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Scalable pricing
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          White label
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Hands-on onboarding
                        </Text>
                      </Flex>
                      <Flex gap={'2'} align={'center'}>
                        <CheckmarkIcon
                          className="icon"
                          width={'20px'}
                          height={'20px'}
                        />
                        <Text size={'2'} weight={'medium'}>
                          Enterprise support
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex gap={'2'} className="cta-button-wrapper">
                      <Button
                        variant={'solid'}
                        color="amber"
                        onClick={() => enterpriseContactUsFormOpen()}
                      >
                        Contact Sales
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </Grid>
          </Flex>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
});
