import '../../../components/catalog/components/view-wrapper/view-wrapper.scss';
import { Grid, ScrollArea } from '@radix-ui/themes';
import { workspaceApi } from '@/services/workspace';
import Skeleton from 'react-loading-skeleton';
import { psdManagementApi } from '@/services/psdManagement';
import { useAppSelector } from '@/hooks';
import { SingleTemplate } from '../../../components/catalog/components/view-wrapper/SingleTemplate';
import { SingleMockup } from './single-mockup/SingleMockup';

interface ITemplatestList {
  collectionId: number;
}

export const TemplatestList = ({ collectionId = 0 }: ITemplatestList) => {
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);

  const geyAllMyProjects = workspaceApi.useGetMyProjectsQuery(
    { collectionId: collectionId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <ScrollArea
      className="scroll-area-wrapper"
      type="hover"
      scrollbars="vertical"
      style={{
        height: `calc(100vh - 125px ${showNotificationBar ? '- 50px' : ''})`,
      }}
    >
      <Grid className="view-wrapper" gap={'2'} px={'5'} pt={'1'}>
        {geyAllMyProjects.isLoading
          ? [...Array(12)].map((item, index) => (
              <Skeleton key={`${index}-item`} className="single-template" />
            ))
          : geyAllMyProjects.data?.data
              ?.slice(0)
              ?.reverse()
              ?.map((template) => (
                <SingleMockup key={template.id} myTemplate={template} />
              ))}
      </Grid>
    </ScrollArea>
  );
};
