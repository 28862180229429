import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { DataModel, DataModelArray } from './types';
import { v4 as uuidv4 } from 'uuid';
import {
  formatDateToShortString,
  formatNumberWithThousands,
  formatReportDate,
} from '@/helpers';

export type DailyRenerReportResponse = Record<string, number>;

export interface DailyRenderReport {
  id: string;
  data: {
    x: string;
    y: string;
  }[];
}

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getDailyRendersLast30Days: builder.query<
      DataModelArray<DailyRenderReport>,
      { apiKeyUUID: string }
    >({
      query: ({ apiKeyUUID }) =>
        `reports/daily-renders-last-30-days${
          apiKeyUUID ? '?api_key_uuid=' + apiKeyUUID : ''
        }`,
      transformResponse: (response: DataModel<DailyRenerReportResponse>) => {
        // Transform object into array of { date, value }
        const transformedArray = [
          {
            id: uuidv4(),
            data: Object.entries(response.data).map(([date, value]) => ({
              x: formatDateToShortString(date),
              y: value.toString(),
            })),
          },
        ];

        return {
          data: transformedArray,
          message: '',
          success: true,
        };
      },
    }),
  }),
});

export const {} = reportsApi;
