import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  DataModel,
  DataModelArray,
  Mockup,
  MockupCollection,
  Project,
  PublicCatalog,
} from './types';

export const mockupBundlesApi = createApi({
  reducerPath: 'mockupBundlesApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getMockupBundle: builder.query<
      DataModel<{
        mockup_bundle: { uuid: string };
        mockups: Mockup[];
      }>,
      { mockupBundleUUID: string }
    >({
      query: ({ mockupBundleUUID }) =>
        `mockup-bundles/${mockupBundleUUID}/get-bundle-mockups`,
    }),
    createMockupBundle: builder.mutation<
      DataModel<{
        mockup_bundle: { uuid: string };
        mockups: Mockup[];
      }>,
      { psd_ids: number[]; mockup_ids: number[]; design_ids: number[] }
    >({
      query: (payload) => ({
        url: `mockup-bundles`,
        method: 'POST',
        body: payload,
      }),
    }),
    getPublicCatalogs: builder.query<DataModelArray<PublicCatalog>, void>({
      query: () => `mockup/catalogs/public`,
    }),
    getMyProjects: builder.query<DataModelArray<Project>, void>({
      query: (payload) => `mockup/catalogs/my_projects`,
    }),
    getDesigns: builder.query<DataModelArray<any>, void>({
      query: () => `designs`,
    }),
  }),
});

export const {} = mockupBundlesApi;
