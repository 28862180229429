import { Helmet } from 'react-helmet-async';
import './mockup-bundles.scss';
import { Flex, Text } from '@radix-ui/themes';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';

export const MockupBundles = () => {
  return (
    <Flex
      className="view-wrapper-mockup-bundles"
      direction={'column'}
      gap={'4'}
    >
      <Helmet>
        <title>Dynamic Mockups | Mockup Bundles</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        mt={'5'}
        mx={'5'}
        align={'center'}
        justify={'between'}
      >
        <Text weight={'bold'} size={'5'}>
          Mockup Bundles
        </Text>
      </Flex>
      <ViewWrapper />
    </Flex>
  );
};
