import { Flex, Box } from '@radix-ui/themes';
import './create-mockups-footer.scss';
import { useAppSelector } from '@/hooks';
import { CSSProperties } from 'react';

export const CreateMockupsFooter = () => {
  const {
    designs,
    myTemplates,
    publicMockups,
    selectedDesignIds,
    selectedPsdIds,
    selectedTemplateIds,
  } = useAppSelector((state) => state.mockupBundlesReducer);

  const getMockupImage = () => {};

  return (
    <Flex
      className="footer"
      justify={'between'}
      align={'center'}
      gap={'4'}
      py={'2'}
      px={'4'}
      pl={'5'}
      style={
        {
          '--mockups-count': selectedTemplateIds.length + selectedPsdIds.length,
        } as CSSProperties
      }
    >
      {/* {selectedPsdIds.map((id, index) => (
        <Box
          className="card"
          style={
            {
              '--index': index * 20 + 'px', // Pass unique offset for each card
            } as React.CSSProperties
          }
        >
          {id}
        </Box>
      ))} */}
    </Flex>
  );
};
