import { Flex, Grid, ScrollArea } from '@radix-ui/themes';
import Skeleton from 'react-loading-skeleton';
import { useAppSelector } from '@/hooks';
import { psdManagementApi } from '@/services/psdManagement';
import { PhotoshopFilesSingleTemplate } from './PhotoshopFilesSingleTemplate';
import { useEffect, useRef, useState } from 'react';
import { UploadPsdDialog } from '../UploadPsdDialog';
import { PrivatePhotoshopFile } from '@/services/types';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface IPhotoshopFiles {
  categoryId: number;
  resetTabs: () => void;
}

export const PhotoshopFiles = ({
  categoryId = 0,
  resetTabs,
}: IPhotoshopFiles) => {
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { photoshopFiles, photoshopFilesCategories } = useAppSelector(
    (state) => state.psdManagementReducer
  );
  const uploadPsdDialog = useRef();
  const getPrivatePsds = psdManagementApi.useGetPrivatePsdsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });
  psdManagementApi.useGetPsdCategoriesQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  const [categorizedFiles, setCategorizedFiles] = useState<
    PrivatePhotoshopFile[]
  >([]);
  const isAppPro = useFeatureFlagEnabled('app-pro');

  useEffect(() => {
    if (categoryId === 0) {
      setCategorizedFiles(photoshopFiles);
    } else {
      const data = photoshopFiles.filter(
        (pf) => pf.psd_category_id === categoryId
      );
      setCategorizedFiles(data);
    }
  }, [categoryId, photoshopFiles, photoshopFilesCategories]);

  return (
    <ScrollArea
      className="scroll-area-wrapper"
      type="hover"
      scrollbars="vertical"
      style={{
        height: `calc(100vh - 129px ${showNotificationBar ? '- 50px' : ''})`,
      }}
    >
      <Flex
        direction={'column'}
        px={'6'}
        className="categories-accorion-wrapper"
      >
        <UploadPsdDialog ref={uploadPsdDialog} />

        <Grid gap={'4'} className="view-wrapper">
          {getPrivatePsds.isLoading
            ? [...Array(12)].map((item, index) => (
                <Skeleton key={`${index}-item`} className="single-template" />
              ))
            : categorizedFiles
                ?.slice(0)
                ?.reverse()
                ?.map((file) => (
                  <PhotoshopFilesSingleTemplate
                    key={file.psd_id}
                    privatePsd={{
                      psd_id: file.psd_id,
                      thumbnail: file.thumbnail,
                      name: file.name,
                      psd_category_id: file.psd_category_id,
                      psd_url: file.psd_url,
                      psd_availability_id: file.psd_availability_id,
                      subscription_type_id: file.subscription_type_id,
                      is_ai_mockup: file.is_ai_mockup,
                      source_attribution: file.source_attribution,
                    }}
                  />
                ))}
        </Grid>
      </Flex>
    </ScrollArea>
  );
};
